:root {
  --Lato: "Lato", sans-serif;
  --Poppins: "Poppins", sans-serif;
  --Roboto: "Roboto", sans-serif;
}
.jbpos_tabbtn {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 10px;
  height: 50px;
  max-height: 50px;
  border-bottom: 1px solid lightblue;
  cursor: pointer;
  margin-bottom: 10px;
  overflow-x: auto;
  white-space: nowrap;
}
.jbpos_tabbtn::-webkit-scrollbar {
  display: none;
}
.jbpos_tabbtn .jbbtn {
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  font-weight: 700;
  position: relative;
}
.jbpos_tabbtn .jbbtn.active span {
  position: absolute;
  bottom: -2px;
  height: 4px;
  width: 100%;
  background-color: blueviolet;
  border-radius: 1px;
  transition: all 0.5s ease;
}
.jbpos_tabitem {
  padding: 10px;
  transition: all 0.5s ease;
}
.Loading-Popup {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
  position: fixed;
  background: #00000050;
}
.Loading-Design {
  width: 200px;
  height: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: #fff;
  left: 15%;
  transform-origin: 50%;
  animation: circle 0.5s alternate infinite ease;
}
@keyframes circle {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }
  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0%;
  }
}
.circle:nth-child(2) {
  left: 45%;
  animation-delay: 0.2s;
}
.circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
.shadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow 0.5s alternate infinite ease;
}
@keyframes shadow {
  0% {
    transform: scaleX(1.5);
  }
  40% {
    transform: scaleX(1);
    opacity: 0.7;
  }
  100% {
    transform: scaleX(0.2);
    opacity: 0.4;
  }
}
.shadow:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}
.shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
.Loading-Design span {
  position: absolute;
  top: 75px;
  font-size: 20px;
  letter-spacing: 12px;
  color: #fff;
  left: 15%;
}
.Loading {
  position: fixed;
  z-index: 1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}
.Loading-Background {
  height: 50px;
  width: 200px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
}
.Loading span {
  font-size: 20px;
  padding-left: 10px;
  color: black;
  font-weight: bold;
}
.Loading-Circle {
  border: 5px solid white;
  border-radius: 50%;
  border-top: 5px solid darkgreen;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.Err-Msg {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  font-size: 10px;
  padding: 0;
  margin: 0;
  height: 30px;
}

.Progress-Container {
  height: 200px;
  width: 300px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.Progress-Bar {
  width: 100%;
  height: 15px;
  border-radius: 3px;
  border: 1px solid #ddd;
  padding: 0;
  margin: 0;
}
.success-checkmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;
}
.check-icon {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 50%;
  box-sizing: content-box;
  border: 4px solid #4caf50;
}
.check-icon::before {
  top: 3px;
  left: -2px;
  width: 30px;
  transform-origin: 100% 50%;
  border-radius: 100px 0 0 100px;
}
.check-icon::after {
  top: 0;
  left: 30px;
  width: 60px;
  transform-origin: 0 50%;
  border-radius: 0 100px 100px 0;
  animation: rotate-circle 4.25s ease-in;
}
.check-icon::before,
.check-icon::after {
  content: "";
  height: 100px;
  position: absolute;
  background: #ffffff;
  transform: rotate(-45deg);
}
.icon-line {
  height: 5px;
  background-color: #4caf50;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 10;
}
.icon-line.line-tip {
  top: 46px;
  left: 14px;
  width: 25px;
  transform: rotate(45deg);
  animation: icon-line-tip 0.75s;
}
.icon-line.line-long {
  top: 38px;
  right: 8px;
  width: 47px;
  transform: rotate(-45deg);
  animation: icon-line-long 0.75s;
}
.icon-circle {
  top: -4px;
  left: -4px;
  z-index: 10;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  box-sizing: content-box;
  border: 4px solid rgba(76, 175, 80, 0.5);
}
.icon-fix {
  top: 8px;
  width: 5px;
  left: 26px;
  z-index: 1;
  height: 85px;
  position: absolute;
  transform: rotate(-45deg);
  background-color: #ffffff;
}
@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}
@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
.Excel-btn {
  outline: none;
  border: none;
}
.btn-Progress {
  color: #1b5e20;
  margin: 3px !important;
  height: 30px;
  padding: 5px;
  min-width: 100px;
  transition: 0.4s ease;
  border: 2px solid #1b5e20;
  background-color: white;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
}
.btn-Progress.Show {
  border: none;
}
.btn-Progress.Show span {
  animation: rotate 2s linear infinite;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  border: 2px solid rgb(141, 45, 231);
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#panel {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: white;
  z-index: 1;
}
#panel:hover {
  border-color: #444;
}
#loading8-1,
#loading8-2,
#loading8-3,
#loading8-4,
#loading8-5,
#loading8-6 {
  display: block;
  position: absolute;
  margin: 0;
  width: 5px;
  height: 5px;
}
#loading8-1 #CircleBottom,
#loading8-2 #CircleMiddle,
#loading8-3 #CircleTop,
#loading8-4 #CircleRight,
#loading8-5 #CircleRight1,
#loading8-6 #CircleRight2 {
  display: block;
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}
#loading8-1 #CircleBottom #ball,
#loading8-2 #CircleMiddle #ball,
#loading8-3 #CircleTop #ball,
#loading8-4 #CircleRight #ball,
#loading8-5 #CircleRight1 #ball,
#loading8-6 #CircleRight2 #ball {
  width: 7px;
  height: 7px;
  display: block;
  position: absolute;
  background-color: #06c;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  margin: 0 0 0 20px;
}
#loading8-1 #CircleBottom {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
#loading8-2 #CircleMiddle {
  -webkit-transform: rotate(50deg);
  -moz-transform: rotate(50deg);
  -o-transform: rotate(50deg);
  -ms-transform: rotate(50deg);
  transform: rotate(50deg);
}
#loading8-3 #CircleTop {
  -webkit-transform: rotate(100deg);
  -moz-transform: rotate(100deg);
  -o-transform: rotate(100deg);
  -ms-transform: rotate(100deg);
  transform: rotate(100deg);
}
#loading8-4 #CircleRight {
  -webkit-transform: rotate(150deg);
  -moz-transform: rotate(150deg);
  -o-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
#loading8-5 #CircleRight1 {
  -webkit-transform: rotate(200deg);
  -moz-transform: rotate(200deg);
  -o-transform: rotate(200deg);
  -ms-transform: rotate(200deg);
  transform: rotate(200deg);
}
#loading8-6 #CircleRight2 {
  -webkit-transform: rotate(250deg);
  -moz-transform: rotate(250deg);
  -o-transform: rotate(250deg);
  -ms-transform: rotate(250deg);
  transform: rotate(250deg);
}
#loading8-1,
#loading8-2,
#loading8-3,
#loading8-4,
#loading8-5,
#loading8-6 {
  -webkit-animation: cwSpin 1.3s linear 0.3s infinite;
  -moz-animation: cwSpin 1.3s linear 0.3s infinite;
  -o-animation: cwSpin 1.3s linear 0.3s infinite;
  -ms-animation: cwSpin 1.3s linear 0.3s infinite;
  animation: cwSpin 1.3s linear 0.3s infinite;
}
@-webkit-keyframes cwSpin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes cwSpin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-ms-keyframes cwSpin {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
  }
}
@-o-keyframes cwSpin {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
  }
}
@keyframes cwSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#PrintPdf {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: auto;
  padding: 0;
  border: 1px solid;
}
.scrsearch-container {
  margin: 0 20px;
  padding: 10px 0px;
}
#search-addon {
  width: 35px;
  height: 100%;
  background-color: #e9ebf3;
  color: black;
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}
.Company-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: first baseline;
}
.Company-content {
  position: relative;
  color: var(--lbl-color);
  font-weight: bold;
  background: #fff;
  padding: 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-radius: 10px;
  margin-top: 3%;
  min-width: 60%;
  max-width: 60%;
  padding-top: 20px;
}
.company-wrapper {
  min-height: 70vh;
  max-height: 70vh;
  overflow: auto;
  padding: 0 20px;
}
.Company-content .common-tab {
  padding-left: 20px;
}
.dt-company {
  display: grid;
  grid-template-columns: auto;
  margin-top: 20px;
  gap: 1rem;
  padding: 10px;
}
.dt-company .dt-button {
  white-space: nowrap;
  width: 100%;
  border: none;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: none;
  padding: 15px 0 0 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-radius: 7px;
}
.dt-company .dt-button h1 {
  position: relative;
  text-align: left;
  width: 100%;
  font-weight: 700;
  font-size: 17px;
  text-transform: uppercase;
  padding: 5px 10px;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 100px;
  align-items: center;
  white-space: wrap;
  word-wrap: wrap;
}
.dt-company .dt-button.active h1 {
  border-bottom: 1px solid #ddd;
}
.dt-company .dt-button.selected h1 {
  color: darkgreen;
}
.dt-company .dt-button h1 .cmp-btns {
  transition: all 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 30px;
  font-size: 13px;
}
.dt-company .dt-button h1 .cmp-btns i {
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.dt-company .dt-button h1 .cmp-btns i:hover {
  color: blue;
  transform: scale(1.3);
}
.dt-company .dt-button h1 .cmp-btns .fa-caret-down {
  position: absolute;
  right: 10px;
}
.dt-company .dt-button.active h1 .cmp-btns .fa-caret-down {
  transform: rotate(-180deg);
  color: blueviolet;
}
.dt-company .dt-button .btn-section {
  display: none;
}
.dt-company .dt-button.active .btn-section {
  display: flex;
  padding-right: 10px;
}
.fyear-container {
  width: 100%;
  padding: 10px;
  transition: all 0.5s ease-in-out;
}
.ht-fyearh {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  color: gray;
  border: 0;
  text-align: left;
  font-size: 13px;
}
.ht-fyear {
  width: 100%;
  display: grid;
  grid-template-columns: 150px 150px 150px 150px auto;
  align-items: center;
  background: none;
  color: gray;
  border: 0;
  text-align: left;
  font-size: 12px;
  margin: 0;
}
.dt-fyear {
  width: 100%;
  color: var(--lbl-color);
  padding: 0;
  font-weight: 700;
  margin: 0;
}
.dt-fyear .fy-button {
  width: 100%;
  display: grid;
  grid-template-columns: 150px 150px 150px 150px auto;
  align-items: flex-start;
  border: none;
  outline: none;
  background: none;
  font-size: 12px;
  line-height: 50px;
}
.dt-company .btn-section {
  position: relative;
}
.fy-button span {
  text-align: left;
}
.fy-button.selected {
  color: darkgreen;
}
.fy-button i {
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.fy-button i:hover {
  color: blue;
  transform: scale(1.3);
}
.fy-button.active .fy-btn .fa-caret-down {
  transform: rotate(-180deg);
  color: blueviolet;
}
.fy-button .fy-btn {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.branch-container {
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}
.ht-branch {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 12px;
  color: gray;
  line-height: 0;
  margin-bottom: 10px;
}
.dt-branch {
  width: 100%;
  color: black;
}
.br-button {
  width: 100%;
  display: grid;
  grid-template-columns: auto 150px;
  align-items: flex-start;
  border: none;
  outline: none;
  background: none;
  font-size: 12px;
  line-height: 50px;
}
.br-button span {
  text-align: left;
}
.br-button i {
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.br-button i:hover {
  color: blue;
  transform: scale(1.3);
}
.dt-branch .br-name {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 5px;
}
.dt-branch .br-name h2 {
  font-size: 12px;
  margin: 0;
  line-height: 30px;
}
.dt-branch .br-name span {
  font-size: 10px;
  color: var(--primary);
  font-weight: bold;
  margin: 0;
  line-height: 0;
}
.br-button.selected {
  color: var(--primary);
}
.br-button.selected h2 {
  color: var(--primary) !important;
  font-weight: bold;
}
.br-button.selected h2 span {
  color: #678efe !important;
  font-weight: bold;
}
.dt-branch .br-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 30px;
  font-size: 10px;
  transition: all 0.5s ease-in;
}
.dt-branch i {
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.dt-branch i:hover {
  color: blue;
  transform: scale(1.3);
}

.comp-logoutbtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  padding: 0 20px;
}
.comp-logoutbtn button {
  border: none;
  outline: none;
  color: gray;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  font-size: 14px;
  background: none;
  transition: all 0.5s ease-in-out;
}
.comp-logoutbtn button i {
  font-size: 12px;
}
.comp-logoutbtn button:hover {
  color: blueviolet;
}
/* ------------------------------Fin Year and Key Message-------------------------- */

.Finkey h5 {
  padding: 0;
  height: 15px;
  font-weight: 700;
  color: darkgreen;
  margin-bottom: 20px;
}
.Finkey p {
  padding: 0;
  font-weight: 500;
  color: #3f4553;
  font-size: 14px;
}
.What_Phone {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
.What_Phone span {
  width: 35px;
  height: 30px;
  border: 1px solid lightblue;
  border-radius: 3px 0 0 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 13px;
  background: #eef6ff;
  color: gray;
}
.What_Phone input {
  border-radius: 0 3px 3px 0;
}

.restorcon {
  margin-top: 50px;
  width: 100%;
  text-align: center;
  font-size: 15px;
  font: 500 24px/35px var(--roboto) !important;
  color: olivedrab !important;
  font-weight: 500;
  animation: blink 2.5s infinite !important;
  transition: all 0.5s ease-in-out;
}
/* Define the keyframes for the blinking effect */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.resprogress {
  margin-top: 10px;
  position: relative;
  width: 100%;
  height: 100%;
}
.steps {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 9px;
  color: #99a2a8;
  font: 500 13px/1 "Roboto", sans-serif;
}
.step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  row-gap: 10px;
}
.step span {
  height: 30px;
  width: 30px;
  background: #eaf0f4;
  color: #99a2a8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 3;
  cursor: pointer;
}
.step.completed {
  color: #5cb85c;
  font: normal normal normal 30px/50px;
}
.step.completed span {
  background: #5cb85c;
  color: #fff;
}
#percentpen {
  position: absolute;
  top: 10px;
  left: 30px;
  height: 10px;
  width: 90%;
  background-color: #eaf0f4;
  transition: all 0.35s ease-in;
  z-index: 1;
}
#percentcomp {
  position: absolute;
  top: 10px;
  left: 30px;
  height: 10px;
  background-color: #5cb85c;
  transition: all 0.35s ease-in;
  z-index: 2;
}
.submitbtn {
  outline: none;
  width: 168px;
  border-radius: 5px;
  cursor: pointer;
  font: 700 14px/40px "Roboto", sans-serif;
  border: 1px solid #99a2a8;
  text-align: center;
  transition: all 300ms linear 0s;
  color: #5f6771;
  background: transparent;
}
.submitbtn i {
  font-size: 20px;
  padding-right: 10px;
}
.submitbtn:hover,
.submitbtn:focus {
  background: #5cb85c;
  border-color: #5cb85c;
  color: #fff;
}
.filebtn {
  min-width: 150px;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  font: 700 14px/40px "Roboto", sans-serif;
  border: 1px solid #99a2a8;
  text-align: center;
  transition: all 300ms linear 0s;
  color: #5f6771;
  background: transparent;
}
.filebtn i {
  font-size: 20px;
  padding-right: 10px;
}
.filebtn:hover,
.filebtn:focus {
  background: #5cb85c;
  border-color: #5cb85c;
  color: #fff;
}
.filebtn input {
  display: none !important;
}
.file_added {
  margin-top: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.file_added li {
  list-style: none;
}
.file_added a {
  font: 400 15px/28px "Roboto", sans-serif;
  color: #5cb85c;
  font-weight: 500;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
}
.file_added i {
  font-size: 14px;
  margin: 0 5px;
}
.file_added i:first-child {
  transform: rotate(-35deg);
}
.file_added i:last-child:hover {
  color: #5f6771;
  transform: scale(1.1);
}
.file_added a span:hover {
  text-decoration: underline;
}
/* -------------------------------------------- Admin Panel Style ------------------------------- */
.Admin-container {
  display: block;
  padding: 0;
  border-radius: 0;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
}
.Admin-container:nth-child(2) {
  border-left: 1px solid #ddd;
}
.Admin-container button {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  border-radius: 5px;
  border: 1px;
  outline: none;
  color: black;
  padding: 6px 12px;
  background-color: #ddd;
  transition: all 0.5s ease-in-out;
}
.Admin-container button:hover {
  background: blueviolet;
  color: #fff;
  cursor: pointer;
}
.client-list {
  width: 100%;
}
.client-list th {
  background: #fff;
  color: gray;
  border: 0;
}
.client-list tr {
  border: 0;
  font-size: 13px;
}
.client-list .expiry {
  color: red;
}
.client-list td {
  border: 0;
  font-size: 13px;
  border-bottom: 1px solid #ddd;
}
.client-list .cmpname {
  font-weight: 500px !important;
}
.back-hdr {
  font-size: 20px;
  color: black;
}
/* ========================================  image Selection Style ============================== */
.img-container {
  width: 150px;
  height: 150px;
  display: flex;
  place-items: center;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: var(--img-radius);
  padding: 5px 10px;
  border: 1px solid #ddd;
  background: #fff;
}
.img-content {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: var(--img-radius);
}
.img-content .img-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: var(--img-radius);

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
}
.img-content .img-wrapper .image {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: --img-ravar(--img-radius);
}
.img-content .img-wrapper .image img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: var(--img-radius);
}
.img-content .img-wrapper .image i {
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
}
.img-content .img-wrapper #custom-btn {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: darkgreen;
}
.img-content .img-wrapper.active #custom-btn {
  display: none;
}
.img-content .img-wrapper .text {
  font-size: 10px;
  font-weight: 500;
  color: #5b5b7b;
}
.img-content .img-wrapper #cancel-btn i {
  position: absolute;
  font-size: 15px;
  right: 15px;
  top: 0;
  right: 0;
  height: 20px;
  width: 30px;
  background: darkgreen;
  color: white;
  cursor: pointer;
  display: none;
  border-radius: 0 0 0 20px;
}
.img-content .img-wrapper.active:hover #cancel-btn i {
  display: block;
}
.img-content .img-wrapper #cancel-btn i:hover {
  font-size: 17px;
}
.img-content .img-wrapper .file-name {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 5px 0;
  font-size: 10px;
  background: white;
  color: gray;
  display: none;
  font-weight: normal;
}
.img-content .img-wrapper.active:hover .file-name {
  display: block;
}
