.dashb-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #ecf6fd;
  display: grid;
  grid-template-columns: auto;
  gap: 10px;
  padding-bottom: 50px;
}
.dashb-header {
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
  padding: 0 10px;
  margin-top: 5px;
  font-family: "Poppins", sans-serif;
}
.dashb-header h1 {
  font-size: 20px;
  color: black;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}
.dashhead-input {
  display: flex;
  justify-content: flex-end;
}
.dash-date {
  position: relative;
  height: 35px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 5px;
  font-size: 12px;
  color: #8637f6;
  padding: 0 0 0 5px;
  border-radius: 5px;
  background: #fff;
}
.dash-date i {
  color: #8637f6;
  font-size: 13px;
  cursor: pointer;
}
.dash-date i:hover {
  color: blueviolet;
}
.dash-date span {
  font-size: 12px;
  color: #8637f6;
}
.dash-date .check {
  height: 100%;
  background-color: #8637f6;
  color: var(--white-color);
  font-weight: normal;
  padding: 4px 10px;
  border-radius: 0 5px 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: all 0.4s ease;
}
.dash-date .check:hover {
  background: darkgreen;
  color: var(--white-color);
}
.datepicker-toggle {
  display: inline-block;
  position: relative;
  width: 18px;
  height: 19px;
}
.datepicker-toggle-button {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.datepicker-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  box-sizing: border-box;
}
.datepicker-input::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.dashb-content.first {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 0.3rem;
}
.dashb-content.secount {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 25% auto 25%;
  gap: 0.3rem;
}
.dashb-content.third {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 0.3rem;
}
.dashb-content .Cart {
  padding: 5px;
  border-radius: 10px;
  display: grid;
  grid-template-columns: auto;
  align-items: flex-start;
  gap: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: #fff;
}
.dashb-content .Cart1 {
  color: blue;
  padding: 10px;
  text-transform: uppercase;
}
.dashb-content .Cart2 {
  color: red;
  padding: 10px;
  text-transform: uppercase;
}
.dashb-content .Cart3 {
  color: green;
  padding: 10px;
  text-transform: uppercase;
}
.dashb-content .Cart4 {
  color: orange;
  padding: 10px;
  text-transform: uppercase;
}
.dashb-content .Cart .chart {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
}
.dashb-content .Cart .chart strong {
  font-size: 25px;
  font-weight: bold;
  color: black;
}
.dashb-content .Cart .chart span {
  font-size: 15px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}
.dashb-content .Cart .chart i {
  margin-right: 10px;
  font-size: 17px;
}
.dashb-content .Cart .chart .sal-up,
.dashb-content .Cart .chart .sal-down {
  font-weight: 500;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
}
.dashb-content .Cart .chart .sal-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 5px;
  align-items: flex-end;
  text-align: right;
}
.dashb-content .Cart .chart .sal-up {
  color: gray;
}
.dashb-content .Cart .chart .sal-down {
  color: red;
}
.dashb-content .Cart .chart .sal-cont span {
  font-size: 12px;
  font-weight: 400;
}
.Curr_Sales {
  width: 100%;
  padding: 5px 10px;
}
.Curr_Sales .content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  white-space: nowrap;
  font-weight: bold;
  border-radius: 5px;
  font-size: 12px;
  padding: 10px 0;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  border: 0.5px solid var(--btn-border);
}
.Curr_Sales .content strong {
  color: rgb(67, 11, 119) !important;
  font-weight: bold;
  font-size: 17px;
  font-family: Arial, Helvetica, sans-serif;
}
.total_Count {
  margin: 0;
  padding: 0 10px;
  width: 100%;
}
.total_Count .info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-size: 12px;
}
.total_Count .info span {
  color: black;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}
.total_Count .info strong {
  font-weight: bold;
  color: rgb(67, 11, 119) !important;
}
.Invoice-Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  cursor: pointer;
}
.Invoice-Container .invoice {
  padding: 0 10px;
}
.Invoice-Container .invoice:not(:last-child) {
  border-bottom: 1px solid rgb(241, 246, 248);
}
.Inv-Content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
}
.Inv-Content strong {
  font-size: 11px;
}
.Inv-Content span {
  color: gray;
  font-size: 10px;
}
.Inv-Content .info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5px;
  font-size: 10px;
}
.Inv-Content .info small {
  font-weight: bold;

  font-size: 10px;
}
.Inv-Content .info .hover:hover {
  text-decoration: underline;
  color: blue;
}
.salesgrap-cont {
  position: relative;
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
  display: block;
  border-radius: 7px;
  margin: 0;
  padding: 0;
}
.salesgrap {
  width: 100%;
  height: 250px;
  position: relative;
  font-size: 8px;
  transition: 0.3s ease-in-out;
}
.recharts-surface {
  padding: 0;
  margin: 0;
  margin-left: -30px;
}
.graptooltip {
  color: black;
  display: flex;
  flex-direction: column;
  column-gap: 5px;
  font-size: 10px;
  padding: 5px;
  align-items: center;
  text-align: center;
  font-weight: normal;
}
.IncomegGraph-style {
  color: white;
  font-size: 10px;
  background-color: rgb(255, 255, 255);
  transition: 0.3s ease-in-out;
  width: 100%;
  height: 300px;
  border: 1px dotted lightblue;
  border-radius: 5px;
  padding: 0;
}
.recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child,
.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
  stroke-opacity: 0;
}
.Cart h3 {
  width: 100%;
  text-transform: uppercase;
  font-size: 12px;
  border-bottom: 1px solid rgb(241, 246, 248);
  color: black;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}
.Cart .out-amt {
  text-align: right;
}
.Cart table tr td:first-child {
  padding-left: 10px;
  white-space: nowrap;
}
.Cart table tr td:last-child {
  padding-right: 10px;
}
.Cart table tr th:first-child {
  padding-left: 10px;
}
.Cart table tr th:last-child {
  padding-right: 10px;
}
.sum-table {
  position: relative;
  width: 100%;
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  gap: 0 !important;
  padding: 0 !important;
}
.sum-table h3 {
  margin: 0;
}
.tbl-container {
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  color: black;
  cursor: pointer;
  font-weight: 700;
  font-size: 12px;
  border-spacing: 0;
}
.tbl-header {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 25% 25%;
  font-weight: 700 !important;
  color: rgb(67, 11, 119) !important;
  background: white !important;
  font-size: 12px;
  white-space: nowrap;
  padding: 0 10px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}
.tbl-header span:not(:first-child) {
  text-align: right !important;
}
.tbl-body {
  width: 100%;
  max-height: 40vh;
  overflow: auto;
  padding: 0 10px;
}
.tbl-body p {
  display: grid;
  grid-template-columns: 50% 25% 25%;
  font-weight: 600;
}
.tbl-body p span:not(:first-child) {
  text-align: right !important;
}
.tbl-footer {
  width: 100%;
  color: red;
  font-size: 12px;
  padding: 5px;
  text-align: left !important;
  font-weight: 700;
  display: grid;
  grid-template-columns: 50% 25% 25%;
  padding: 0 10px;
}

/* =================================Control Panel================================== */

.Dash-container {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
}
.Dash-content {
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
}

.Dash-card:first-child {
  width: 25%;
  height: 100%;
  background: white;
  padding: 0;
  overflow: hidden;
  box-shadow: 0 0 0 hsla(var(--hue), 10%, 10%, 0.5) inset;
  border-right: 0.5px solid var(--btn-border);
}
.Dash-card:last-child {
  width: 75%;
  height: 100%;
  padding: 0 0 0 0;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
}
.Dash-card1 {
  width: 100%;
  height: 100%;
  padding: 0 0 0 0;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
}
.Dash-wrapper {
  width: 100%;
  padding: 0 10px 100px 0;
  height: 100%;
  overflow: auto;
  background: #fff;
}
.dash-header {
  width: 100%;
  padding: 10px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--btn-border);
}
.Dash-card1 .dash-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dash-header h1 {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  color: var(--primary);
}
.dash-header button {
  font-weight: bold;
  border: 1px solid var(--btn-border);
  outline: none;
  color: var(--primary);
  border-radius: 5px;
  padding: 0 18px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
}
.dash-header button:hover {
  background: var(--primary);
  color: var(--white-color);
}
.dash-header.script {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  column-gap: 10px;
}
.Dash-container .trans-tab {
  width: 100%;
}
.Dash-section {
  position: sticky;
  bottom: 0;
  left: 0;
  padding: 5px 0;
  margin: 0;
  width: 100%;
  padding-right: 30px;
  row-gap: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 5px;
  z-index: 1;
  height: auto;
  min-height: 50px;
  background-color: #f9f9fb;
  border-top: 1px solid #ddd;
}
.table-admin {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  cursor: pointer;
  height: auto;
  overflow: auto;
}
.table-admin .option-list {
  position: relative;
  width: 100% !important;
  padding: 0;
  margin: 0;
  border-collapse: collapse;
}
.table-admin table thead {
  top: -1px;
  margin: 0;
  padding: 0;
}
.table-admin table thead tr {
  margin: 0;
  padding: 0;
}
.table-admin td,
.table-admin th {
  border: 0;
  border-bottom: 0.5px solid #ddd;
  border-right: 0.5px solid #ddd;
  border-radius: 0 !important;
}

.table-admin th {
  text-align: center;
  border-top: 1px solid #ddd !important;
  background: #f2f2f2 !important;
  font-weight: bold !important;
  padding: 5px;
  color: var(--lbl-color);
}
.table-admin td {
  font-weight: 500;
  padding: 5px;
  font-size: 11px !important;
  word-wrap: break-word;
  word-break: break-all;
}
.table-admin tr td:first-child,
.table-admin tr th:first-child {
  border-left: 1px solid #ddd !important;
}
.table-admin tr:hover,
.table-admin tr:hover td {
  background: none;
}
.table-admin tr:focus {
  outline: none;
}

.admin-DashBoard {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}
.Dash_Home_Icon {
  position: relative;
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 5px;
  font-size: 15px;

  font-weight: 700;
  color: darkblue;
  cursor: pointer;
  padding-right: 50px;
  background-color: #f4f4f4;
}
.Dash_Home_Icon i {
  font-size: 17px;
}
.Dash_Home_Icon:hover {
  color: green;
}
.admin_container {
  display: flex;
  width: 100%;
  height: 100%;
  background: #fff;
  column-gap: 10px;
  position: relative;
  margin: auto;
  padding: 0 10px;
}
.admin-tab {
  display: flex;
  flex-direction: column;
  column-gap: 10px;
  height: 100%;
  width: 300px;
  padding: 10px 0;
  cursor: pointer;
  z-index: 1;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 5px;
  justify-content: flex-start;
}
.admin-tab li {
  list-style: none;
  white-space: nowrap;
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  padding-left: 10px;

  font-weight: 700;
}
.admin-tab li a {
  color: darkgreen;
  text-transform: uppercase;
}
.admin-tab li:hover {
  background-color: #eef6ff;
  color: #3f65af;
  border-left: 4px solid #3f65af;
  border-radius: 3px 0 0 3px;
}
.tab_container {
  width: 100%;
  height: 100%;
  padding: 10px 0;
}
.tab_container .tab_Search_Input {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: left;
  align-items: center;
  width: 100%;
}
.tab_container .tab_Search_Input i {
  background-color: #eef6ff;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid lightblue;
  border-radius: 3px 0 0 3px;
  color: gray;
  border-right: none;
}
.tab_container .tab_Search_Input input {
  border: 1px solid lightblue;
  border-radius: 0 3px 3px 0;
  box-shadow: none;
}
.User_tracking {
  width: 100%;
  height: auto;
  display: flex;
  column-gap: 20px;
  row-gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;

  font-weight: 700;
}
.User_tracking .User_Cart {
  width: calc(100% / 4);
  flex: 1 1 150px;
  height: 80px;
  border-radius: 3px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.User_Input:nth-child(2) {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.User_tracking .User_Cart label {
  font-size: 20px;
}
.User_tracking .User_Cart:first-child {
  border-left: 5px solid #3f65af;
  color: #3f65af;
}
.User_tracking .User_Cart:nth-child(2) {
  border-left: 5px solid red;
  color: red;
}
.User_tracking .User_Cart:nth-child(3) {
  border-left: 5px solid orange;
  color: orange;
}
.User_tracking .User_Cart:nth-child(4) {
  border-left: 5px solid rosybrown;
  color: rosybrown;
}
#DashBoard_Tab {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 5px;
}
.User_List_Container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 10px;
  overflow: auto;
  max-height: 500px;
  cursor: pointer;
}
.User_List_Container::-webkit-scrollbar-track {
  border-radius: 2px;
  background-color: #ddd;
}
.User_List_Container::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.User_List_Container::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: green;
}
.User_List_Cart {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 3px;
  padding: 0 10px;
  position: relative;
}
.User_List_Cart i {
  position: absolute;
  top: 10px;
  right: 10px;
  color: green;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid lightblue;
  border-radius: 5px;
}
.User_List_Cart i:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.User_List_Cart_Item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  font-weight: 600;
  color: gray;
}
.User_CompName:hover {
  color: darkblue;
  text-decoration: underline;
  cursor: pointer;
}
.User_List_Cart_Item label {
  font-size: 12px;
}
.Script_Container h5 {
  font-weight: 700;
  display: flex;
  justify-content: center;
}
.Script_Note {
  margin-top: 10px;
  line-height: 10px;
}
.Script_Note label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: red;
}
.Script_Note p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  column-gap: 10px;
  font-size: 13px;
  font-weight: 400;
}
.Script_Note p span {
  font-size: 20px;
}
.JB_UserTab {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: auto;
  column-gap: 10px;
  border-radius: 3px;
}
.JB_UserTab button {
  padding: 0 10px;
  outline: none;
  border: none;
  font-size: 11px;
  width: 100px;
  height: 30px;
  white-space: nowrap;
  text-transform: uppercase;

  font-weight: 700;
  color: black;
  background: none;
  position: relative;
}
.JB_UserTab button span {
  display: none;
}
.JB_UserTab .Show_Tab {
  position: relative;
}
.JB_UserTab .Show_Tab span {
  display: block;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  transition: all ease-in-out 0.5s;
  height: 4px;
  background: blue;
  border-radius: 1px;
  opacity: 0.5;
}
#DashBoard_Tab,
#NewUser_tab,
#Script_Tab,
#Script_Update_Tab,
#DataBase_Tab {
  border: 1px solid rgb(203, 231, 240);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 7px;
}
.Cld-Backup {
  display: flex;
  justify-content: space-between;
  height: 40px;
  border-bottom: 2px solid rgb(222, 242, 250);
  margin-bottom: 20px;
}
.Cld-Backup label {
  font-size: 20px;
  text-transform: capitalize;
  color: #464270;
  font-weight: 700;
}
.btn-Cld-Backup {
  border: 2px solid darkgreen;
  border-radius: 5px;
  height: 35px;
  min-width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: darkgreen;
  transition: 0.5s ease all;
  background-color: #fff;
  white-space: nowrap;
  font-weight: 700;
}
.btn-Cld-Backup:hover {
  background-color: darkgreen;
  border: 2px solid darkgreen;
  color: #fff;
}
.DBTable {
  max-height: 300px;
  overflow: auto;
}
/* ----------------------------------------- Restore Database Style ---------------------------------------- */
.restorcon {
  margin-top: 20px;
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: #3f4553;
}
.resprogress {
  margin-top: 10px;
  position: relative;
  width: 100%;
  height: 100%;
}
.steps {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 9px;
  color: #99a2a8;
  font: 500 13px/1 "Roboto", sans-serif;
}
.step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  row-gap: 10px;
}
.step span {
  height: 30px;
  width: 30px;
  background: #eaf0f4;
  color: #99a2a8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 3;
  cursor: pointer;
}
.step.completed {
  color: #5cb85c;
  font: normal normal normal 30px/50px;
}
.step.completed span {
  background: #5cb85c;
  color: #fff;
}
#percentpen {
  position: absolute;
  top: 10px;
  left: 30px;
  height: 10px;
  width: 90%;
  background-color: #eaf0f4;
  transition: all 0.35s ease-in;
  z-index: 1;
}
#percentcomp {
  position: absolute;
  top: 10px;
  left: 30px;
  height: 10px;
  background-color: #5cb85c;
  transition: all 0.35s ease-in;
  z-index: 2;
}
.custom-file {
  width: 100%;
}
.submitbtn {
  outline: none;
  height: auto;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  font: 700 14px/40px "Roboto", sans-serif;
  border: 1px solid #99a2a8;
  text-align: center;
  transition: all 300ms linear 0s;
  color: #5f6771;
  background: transparent;
}
.submitbtn i {
  font-size: 20px;
  padding-right: 10px;
}
.submitbtn:hover,
.submitbtn:focus {
  background: #5cb85c;
  border-color: #5cb85c;
  color: #fff;
}
.custom-file .filebtn {
  outline: none;
  width: 100% !important;
  height: 32px;
  border-radius: 5px;
  cursor: pointer;
  font: 700 14px/40px "Roboto", sans-serif;
  border: 1px solid #99a2a8;
  text-align: left;
  transition: all 300ms linear 0s;
  color: #5f6771;
  background: transparent;
}
.custom-file .filebtn i {
  font-size: 20px;
  padding-right: 10px;
}
.custom-file .filebtn:hover,
.custom-file .filebtn:focus {
  background: var(--primary);
  border-color: var(--primary);
  color: var(--white-color);
}
.custom-file .custom-file input {
  display: none !important;
}
.file_added {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.file_added li {
  list-style: none;
}
.file_added a {
  font: 400 15px/28px "Roboto", sans-serif;
  color: #5cb85c;
  font-weight: 500;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
}
.file_added i {
  font-size: 14px;
  margin: 0 5px;
}
.file_added i:first-child {
  transform: rotate(-35deg);
}
.file_added i:last-child:hover {
  color: #5f6771;
  transform: scale(1.1);
}
.file_added a span:hover {
  text-decoration: underline;
}
@media (max-width: 900px) {
  .dashb-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .dash-date {
    width: 100%;
  }
  .dashb-content.first {
    grid-template-columns: auto auto;
  }
  .dashb-content.secount {
    grid-template-columns: auto;
  }
  .dashb-content.third {
    grid-template-columns: auto;
  }
  .Dash-container {
    width: 100%;
    display: grid;
    grid-template-columns: auto;
  }
  .Dash-card {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .dashb-content.first {
    grid-template-columns: auto;
  }
  .dashb-content.secount {
    grid-template-columns: auto;
  }
  .dashb-content.third {
    grid-template-columns: auto;
  }
}
