:root {
  --primary: #1b5e20;
  --secondary: #b9f6ca;
  --black: #000;
  --white: #fff;
  --header-height: 3rem;
  --nav-width: 68px;
  --first-color: #1b5e20;
  --first-color-light: #afa5d9;
  --white-color: #f7f6fb;
  --normal-font-size: 1rem;
  --z-fixed: 100;
  --select-arrow: var(--select-border);
  --Backgound-color: #ecf6fd !important;
  --border-color: #add8e6;
  --btn-color-green: #3a833a;
  --Lato: "Lato", sans-serif;
  --Poppins: "Poppins", sans-serif;
  --Roboto: "Roboto", sans-serif;
  --input-color: #99a3ba;
  --input-border: #cdd9ed;
  --input-background: #fff;
  --input-placeholder: #cbd1dc;
  --input-border-focus: #275efe;
  --group-color: var(--input-color);
  --group-border: var(--input-border);
  --group-background: #eef4ff;
  --group-color-focus: #fff;
  --group-border-focus: var(--input-border-focus);
  --Icon-color: #678efe;
  --btn-border: #cdd9ed;
  --Input-Font: "Mukta Malar", Arial;
  --lbl-color: #3f4553;
  --border: 1px solid #ddd;
}
.Trans-container {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  display: block;
  overflow: hidden;
}
.Trans-card {
  width: 100%;
  height: 100%;
  padding: 50px 0 0 0;
  overflow: hidden;
}
.Trans-wrapper {
  padding: 10px 10px 50px 10px;
  height: 100%;
  overflow: auto;
}
.config-popup {
  max-width: 300px;
}
.config-popup .popup-body {
  min-height: 50vh;
  max-height: 50vh;
  padding: 10px;
}
.inv-no {
  position: absolute;
  top: 15px;
  right: 10px;
  height: 100%;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--Icon-color);
  z-index: 1;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.inv-no:hover {
  color: var(--input-border-focus);
}
.config-text {
  font-size: 14px;
  text-align: left;
  color: var(--lbl-color);
  white-space: wrap;
}
.config-text1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 12px;
  text-align: left;
  color: var(--lbl-color);
  white-space: wrap;
}
.inv-check {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
}
.inv-check label {
  color: black;
  font-size: 13px;
  padding: 0;
  margin: 0;
}
.net-amount {
  padding: 0;
  border: 1px solid #ddd;
  min-height: 100px;
  border-radius: 5px;
}
.net-amount h1,
.net-amount h2 {
  width: 100%;
  min-height: 50px;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-weight: bold;
}
.net-amount h1 {
  background-color: var(--primary) !important;
  color: var(--white-color);
  border-radius: 5px 5px 0 0;
}
.net-amount h2 {
  color: black;
  border-radius: 0 0 5px 5px;
}
.trans-inputbtn {
  height: 100%;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  column-gap: 20px;
  flex-wrap: nowrap;
}
.trans-inputbtn button {
  cursor: pointer;
  padding: 0 5px;
  margin-top: 5px;
  outline: none;
  background: white;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: darkgreen;
  font-weight: 500;
  border-radius: 5px;
  transition: all 0.5s ease;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 1px solid var(--border-color);
  font-size: 13px;
}
.trans-inputbtn button:hover {
  color: white;
  background: darkgreen;
  transition: all 0.5s ease;
}
.trans-add-btn {
  width: 45%;
  margin-top: 5px;
  outline: none;
  background: white;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: darkgreen;
  font-weight: 700;
  border-radius: 5px;
  transition: all 0.5s ease;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 1px solid var(--border-color);
}
.trans-add-btn:hover {
  color: white;
  background: darkgreen;
  transition: all 0.5s ease;
}

/* ==================================Barcode =================================== */

#Barcode {
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  padding: 5px;
}
.barcode-Scan {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  max-width: 60px;
  max-height: 60px;
  min-width: 60px;
  border-radius: 3px;
  position: relative;
}
.barcode-Scan img {
  position: absolute;
  object-fit: cover;
  max-width: 60px;
  max-height: 60px;
}
.barcode-Scan img:hover {
  background-color: #f2f7fb;
}
.BarcodInput input {
  position: absolute;
  max-width: 60px;
  max-height: 60px;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
}
/* ========================== Address Add Click Button ============================================= */
.addAddress-btn {
  position: absolute;
  top: 0;
  right: 10px;
  border: 1px dashed blue;
  border-radius: 5px;
  color: blue;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 12px;
}
.addAddress-btn:hover {
  color: white;
  background-color: blue;
}
.deliv-add i {
  color: darkgreen;
  cursor: pointer;
  font-weight: bold;
  width: 20px;
  height: 20px;
  font-size: 12px;
}
/* ------------------------------------Expense Income Summary -------------------- */
.sum-container {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  padding: 0;
  border-radius: 5px;
}
.Exp-sum-head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border-bottom: 1px dashed darkgreen;
}
.Exp-sum-head h6 {
  color: black;
  font-weight: 700;
  font-size: 13px;
  padding: 0 10px;
}
.Exp-sum-lbl {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}
.Exp-sum-lbl label {
  color: black;
}
.Exp-sum-foot {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}
.Exp-sum-foot label {
  font-weight: 700;
  font-size: 20px;
  color: darkgreen;
}

/* 
---------------------------------------- Manual Invoice ------------------------------------- */
.Manual-Container {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.Manual-card {
  width: 100%;
  height: 100%;
  padding: 50px 0 0 0;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
}
.Manual-wrapper {
  height: 100%;
  overflow: auto;
  padding-bottom: 50px;
}
.customer-container {
  width: 100%;
  height: auto;
  padding: 20px 20px 10px 20px;
  display: grid;
  grid-template-columns: 20% 80%;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 20px;
}
.customer-container label {
  font-size: 12px;
  color: var(--lbl-color);
}
.party-info {
  max-width: 60%;
  display: block;
}
.party-address {
  width: 100%;
  margin-top: 10px;
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  height: auto;
}
.party-address .address p {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  line-height: 20px;
  font-size: 12px;
  white-space: nowrap;
  height: auto;
}
.party-address .address label {
  color: var(--Icon-color);
}
.party-address .address label .bx {
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}
.party-address .address label .bx:hover {
  color: var(--Icon-color);
}
.party-address .address p strong {
  color: var(--lbl-color);
}
.cpy-billadd {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
  margin: 0;
  color: var(--Icon-color);
  font-size: 14px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}
.cpy-billadd:hover span {
  text-decoration: underline;
}
.vouch-content {
  padding: 0 10px;
}
.vouch-content .jb-col {
  padding-left: 5px;
}
.sett-icon {
  position: absolute;
  top: 15px;
  right: 20px;
  height: 100%;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-icon {
  position: absolute;
  top: 0;
  right: 10px;
  height: 100%;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ----------------------------------------- Manual Invocie table ------------------------------ */
.table-container {
  width: 100%;
  height: auto;
  padding: 10px;
}
.table-wrapper {
  width: 100%;
  height: auto;
  background: #fff;
  border-radius: 10px;
}
.tbl-content {
  padding-bottom: 10px;
  max-height: 50vh;
  min-height: 30vh;
  overflow: auto;
  border: 1px solid var(--btn-border);
  border-bottom: 0;
  border-top: 0;
  border-radius: 10px 10px 0 0;
}
.tbl-manual {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  border-radius: 10px;
  margin-bottom: 10px;
}
.tbl-tr {
  width: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  list-style: none;
  line-height: 40px;
  padding: 0;
  margin: 0;
}
.tbl-tr.hr {
  background: var(--primary);
  color: var(--white-color);
  border-radius: 10px 10px 0 0;
  position: sticky;
  top: 0;
  z-index: 1;
}
.tbl-tr.hr .tbl-td li {
  border-right: 1px solid var(--white-color);
}
.tbl-td {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  gap: 0;
  font-size: 12px;
}
.tbl-td li {
  display: flex;
  padding: 0 5px;
  margin: 0;
}
.tbl-tr.bd {
  border-bottom: 1px solid var(--btn-border);
}
.tbl-tr.bd .tbl-td li {
  height: 60px;
  border-right: 1px solid var(--btn-border);
}
.tbl-fr {
  width: 100%;
  border: 1px solid var(--btn-border);
  border-radius: 0 0 10px 10px;
  height: 60px;
  padding: 5px 0 5px 50px;
}
.tbl-fr input {
  max-width: 300px;
  height: 90%;
}
.tbl-fr input:hover,
.tbl-fr input:focus {
  border: 1px solid var(--input-border-focus) !important;
}

/* ----------------------------------------- Manual Invocie table End------------------------------ */

.btn-inv-group {
  position: sticky;
  bottom: 0;
  left: 0;
  padding: 5px 0;
  margin: 0;
  width: 100%;
  padding-right: 30px;
  row-gap: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 10px;
  z-index: 1;
  height: auto;
  min-height: 50px;
  background-color: #f9f9fb;
  border-top: 1px solid #ddd;
}
.sum-content .bill-disc,
.sum-content .othercharges {
  width: 100%;
  display: block;
  background-color: #f9f9fb;
}
.bill-disc h1 {
  width: 100%;
  font-size: 15px;
  border-bottom: 1px solid #ddd;
  padding: 10px;
  color: #3f4553;
}
.bill-disc .disc-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  row-gap: 10px;
  padding: 0 0 0 10px;
}
.bill-disc .disc-container label {
  color: var(--lbl-color);
}
.othercharges .more-info {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5px;
  padding: 5px;
  margin-top: 5px;
}
.othercharges .more-info span {
  width: auto;
  color: var(--Icon-color);
  font-size: 13px;
  text-align: left;
  cursor: pointer;
}
.othercharges h1 {
  font-size: 15px;
  border-bottom: 1px solid #ddd;
  padding: 10px;
  color: #3f4553;
}
.Otc-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  padding: 5px 0 0 10px;
}
.Otc-content input {
  border: 1px dashed var(--btn-border);
}
.total-content {
  height: auto;
  border-radius: 10px;
  background: white;
}
.total-content h1 {
  font-size: 15px;
  border-bottom: 1px solid #ddd;
  padding: 10px;
  color: #3f4553;
}
.total-content p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  font-size: 13px;
}
.total-content p span {
  text-align: left;
}
.total-content p strong {
  text-align: right;
  color: #3f4553;
}
.total-content p strong sub {
  font-weight: 600;
  padding-right: 5px;
}
.total-content .sum-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--btn-border);
  padding: 10px;
  font-size: 20px;
  color: black;
}
.manul-tax {
  width: auto;
  height: 100%;
  overflow: auto;
  background-color: #fff;
  padding: 0;
  border: 1px solid var(--btn-border);
  border-radius: 10px;
  min-height: 100px;
}
.manul-tax li {
  width: auto;
  list-style: none;
  display: grid;
  grid-template-columns: 100px 100px 100px 100px;
  font-size: 12px;
}
.manul-tax li:not(:last-child) {
  border-bottom: 1px solid var(--btn-border);
}
.manul-tax li.hr {
  width: 100%;
  background-color: var(--primary);
  color: var(--white-color);
  border-radius: 10px 10px 0 0;
  border: 0;
}
.manul-tax li.hr span {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.manul-tax li.hr span:not(:last-child) {
  border-right: 1px solid var(--white-color);
}
.manul-tax li.bd span {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.manul-tax li.bd span:not(:last-child) {
  border-right: 1px solid var(--btn-border);
}

/* Box Icons Design*/
.subicon {
  color: var(--Icon-color);
  font-size: 15px;
  margin-right: 10px;
}
.btn-jb {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  font-size: 14px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  color: #3f4553;
  border: 1px solid var(--btn-border);
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}
.btn-i {
  padding: 5px 30px 5px 10px;
}
.btn-jb span {
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid var(--btn-border);
  transition: background-color 0.3s ease-in-out;
}
.btn-jb span i {
  transition: all 0.7 ease-in-out;
}
.btn-jb:hover {
  color: var(--white-color);
  background-color: var(--primary); /* Darker blue on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}
.btn-jb:hover span i {
  color: var(--white-color);
  transform: rotate(180deg);
}
.btm-total {
  width: 28%;
  position: absolute;
  top: 10px;
  right: 20px;
  display: block;
  background-color: #f9f9fb;
  border-radius: 5px;
  padding: 5px 5px 0 5px;
}
.btm-total h1 {
  font-size: 14px;
  font-weight: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btm-total strong {
  text-align: right;
  color: #3f4553;
}
.btm-total strong sub {
  font-weight: 600;
  padding-right: 5px;
}
.jb-row {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}
.jb-col {
  position: relative;
  box-sizing: border-box;
  max-width: 100%;
  display: block;
  padding: 0;
}
.col1 {
  width: 8.33333%; /* 1 column out of 12 */
}
.col2 {
  width: 16.66667%; /* 2 columns out of 12 */
}
.col3 {
  width: 25%; /* 3 columns out of 12 */
}
.col4 {
  width: 33.33333%; /* 4 columns out of 12 */
}
.col5 {
  width: 41.66667%; /* 5 columns out of 12 */
}
.col6 {
  width: 50%; /* 6 columns out of 12 */
}
.col7 {
  width: 58.33%; /* 7 columns out of 12 */
}
.col8 {
  width: 66.66667%; /* 8 columns out of 12 */
}
.col9 {
  width: 75%; /* 9 columns out of 12 */
}
.col10 {
  width: 83.33333%; /* 10 columns out of 12 */
}
.col11 {
  width: 91.66667%; /* 11 columns out of 12 */
}
.col12 {
  width: 100%; /* 12 columns out of 12 */
}
.btn-popup {
  position: relative;
}
.share-popup {
  position: absolute;
  top: -165px;
  right: 0;
  border-radius: 10px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: none;
  padding: 0;
  margin: 0;
  height: auto;
}
.share-popup.active {
  display: block;
  margin: 0;
  padding: 0;
}
.share-popup ul {
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;
}
.share-popup ul li:first-child {
  border-radius: 10px 10px 0 0;
}
.share-popup ul li:last-child {
  border-radius: 0 0 10px 10px;
}
.share-popup ul li {
  margin: 0;
  width: 100%;
  list-style: none;
  line-height: 40px;
  background-color: #f9f9fb;
  border-bottom: 1px solid #ddd;
  font-size: 13px;
  transition: all 0.5s ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}
.share-popup ul li:hover {
  background-color: var(--primary);
  color: var(--white-color);
  border-radius: 10px;
}

/* ------------------------------------- Appoinment Boooking ---------------------------------------------- */
.order-ht {
  min-height: 50px;
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.Appmnt-Booking {
  background: #fff;
  width: 100%;
  overflow: auto;
  height: 100vh;
  border: 1px solid var(--border-color);
  border-radius: 15px;
}
.Appmnt-Booking table th {
  cursor: pointer;
}
ordertable th.active {
  color: yellowgreen;
}
@media screen and (max-width: 900px) {
  .Trans-container {
    position: relative;
    height: auto;
    overflow: auto;
  }
  .Trans-card,
  .Trans-wrapper {
    width: 100%;
    height: 100%;
    padding: 0;
    overflow: hidden;
  }

  .btn-inv-group {
    position: relative;
    border-top: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 10px;
  }
  .disc-container {
    display: block !important;
  }
}
@media only screen and (max-width: 700px) {
  .inv-table,
  .inv-table thead,
  .inv-table tbody,
  .inv-table th,
  .inv-table td,
  .inv-table tr {
    display: block;
  }
  .inv-table th {
    display: none;
  }
  .inv-table td {
    border: none;
    border-bottom: 1px solid #ddd;
    position: relative;
    padding-left: 50%;
  }
  .inv-table td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    content: attr(data-label);
    font-weight: bold;
  }
  .jb-row {
    display: grid;
    grid-template-columns: 100%;
    row-gap: 10px;
    padding: 10px;
  }
  .jb-col {
    width: 100% !important;
  }
}
