:root {
  --Lato: "Lato", sans-serif;
  --Poppins: "Poppins", sans-serif;
  --Roboto: "Roboto", sans-serif;
}
.container-pos {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
}
.pos-wrapper {
  position: relative;
  width: 100%;
  min-height: 95%;
  max-height: 95%;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 3fr 1fr;
  background: #fff;
  border-radius: 7px;
}
.possection {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: auto;
  background: #fff;
}
.possection:nth-child(2) {
  border-left: 1px solid var(--btn-border);
}
.profile_view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 5px;
}
.profile_view h1 {
  text-transform: uppercase;
  line-height: 0;
  font-size: 17px;
  color: var(--primary);
  margin: 0;
  padding: 0;
  font-weight: bold;
}
.profile-icon {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;
  color: var(--Icon-color);
  font-size: 20px;
}
#cmplogo {
  cursor: pointer;
  width: 35px;
  height: 35px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  z-index: 1;
}
#cmplogo .logo {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: transparent;
}
.search-product {
  width: 100%;
  height: 40px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.return-product {
  position: relative;
  width: 100%;
  height: 40px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
}
.pos-itemlist {
  position: relative;
  width: 100%;
  height: 69vh;
  max-height: 69vh;
  margin: 0 !important;
  padding: 0 !important;
  overflow: auto;
}
.pos-footer {
  position: relative;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
}
.search-box {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  border: none;
  border-bottom: 1px solid #ddd;
}
.search-box input {
  position: relative;
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  padding: 0 10px;
  font-size: 12px !important;
  color: black;
  border-radius: 0;
  background: #fff;
}
.search-box input::placeholder {
  font-size: 15px !important;
  color: black;
}
.search-box i {
  position: relative;
  width: 35px;
  height: 100%;
  transition: all 0.9s ease;
  color: gray;
  font-size: 15px;
  background: #f2f7fb;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px 0 0 10px;
  border-left: 1px solid #ddd;
  font-weight: bold;
}
.search-box i:hover {
  background: darkgreen;
  color: white;
}
.pro-opt {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  z-index: 1000;
  background-color: white;
  border-radius: 0 0 5px 5px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  max-height: 50vh;
  overflow: auto;
  cursor: pointer;
  scroll-behavior: smooth;
  padding: 0;
}
.pro-opt .prosh-opt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
}
.prosh-opt button {
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  text-align: left;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;
  border: none;
  outline: none;
  border-bottom: 1px solid #add8e6;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  padding: 0;
}
.Cust {
  margin: auto;
  max-width: 95%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  margin-top: 45px;
}
.opt-pro {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  transition: all 0.3s ease;
  margin: 0;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
}
.pro-cont1 {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.pro-cont2 {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
}
.opt-pro label {
  font-weight: 600;
}
.prosh-opt::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.prosh-opt::-webkit-scrollbar-track {
  background: white;
  border-radius: 2px;
}
.prosh-opt::-webkit-scrollbar-thumb {
  background-color: silver;
  border-radius: 2px;
}
.prosh-opt button:focus .opt-pro {
  background-color: darkgreen !important;
  color: white !important;
}
.prosh-opt button:focus .opt-pro label {
  color: white !important;
}
.product-table {
  position: relative;
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  white-space: nowrap;
}
.product-table th {
  padding: 7px 5px;
  text-align: center;
  border: none;
  font-weight: 700;
  border-radius: 0 !important;
  border-right: 0.5px solid var(--btn-border);
}
.product-table td {
  white-space: nowrap;
  padding: 5px 10px;
  line-height: 15px;
  font-size: 11px;
  border: none;
}
.product-table td span:first-child {
  color: black;
  font-size: 9px;
  font-weight: 600 !important;
}
.product-table td span:last-child {
  color: blueviolet;
  font-size: 10px;
  font-weight: 600 !important;
}
.product-table td input[type="number"] {
  outline: none;
  border: none;
  font-weight: 500 !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;
}
.product-table td input:focus {
  outline: none;
  border: 1px solid #add8e6;
}
.product-table td input[type="checkbox"] {
  box-shadow: none !important;
  outline: none;
  border: none;
}
.product-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.product-table tbody tr td:first-child {
  border-radius: 0 0 0 5px;
}
.product-table tbody tr td:last-child {
  border-radius: 0 0 5px 0;
}
.product-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}
.pos-footer .info {
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  margin: 0;
}
.pos-footer .info .infoheader {
  width: 100%;
  border-bottom: 1px solid #add8e6;
  color: black;
  font-weight: 700 !important;
  margin: 0;
  font-size: 12px;
  padding: 10px 0 0 10px;
}
.pos-footer .info ul {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px 0 0 10px;
  margin: 0;
}
.pos-footer .info ul li {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  padding: 0;
  margin: 0;
}
.pos-footer .info ul li p:first-child {
  font-size: 10px;
  color: #555;
  text-transform: capitalize;
  font-weight: bold;
}
.pos-footer .info ul li p:last-child {
  font-size: 10px;
  color: #000000;
  font-weight: bold;
}
.billinfo {
  background: #fff;
  height: 100%;
}
.possection .card {
  display: block;
  width: 100%;
}
.possection .card h2:first-child {
  border-top: 1px solid #ddd;
}
.possection .card h2 {
  width: 100%;
  font-size: 13px;
  text-align: left;
  padding: 5px 10px 0 5px;
  color: black;
  font-weight: 600;
  text-transform: uppercase;
}
.possection .card .text {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  padding: 0 10px;
}
.possection .sum .text:last-child {
  border-top: 1px dashed gray;
}
.card.party {
  position: relative;
}
.card.party h2:nth-child(2) {
  color: gray !important;
  font-weight: 500;
}
.card.party span {
  position: absolute;
  right: 10px;
  top: 10px;
  color: blueviolet;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: 0.4s ease all;
  padding: 7px;
  border-radius: 10px;
}
.card.party span:hover {
  transform: scale(1.3);
}
.card.staff span {
  color: blueviolet;
  cursor: pointer;
  display: flex;
  column-gap: 5px;
  align-items: center;
}
.card.staff .select {
  padding: 0 10px 5px 10px;
}
.card.staff span:hover {
  text-decoration: underline;
}
.card.staff .st {
  position: absolute;
  right: 10px;
  top: 0;
  color: blueviolet;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: 0.4s ease all;
  padding: 7px;
  border-radius: 10px;
}
.add-more {
  margin-top: 10px;
  width: 100%;
}
.add-more h1 {
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5px;
  font-size: 12px;
  color: blueviolet;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.5s ease-in;
}
.add-more h1:hover span {
  text-decoration: underline;
}
.card.staff .st:hover {
  transform: scale(1.3);
}
.possection .card .text p {
  font-size: 13px;
  color: gray;
}
.possection .card .text p:last-child {
  font-weight: bold;
  color: black;
}
.possection .Payment {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0 5px;
}
.possection .Payment .icon {
  outline: none;
  border: 0.5px solid var(--btn-border);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  padding: 8px 16px;
}
.possection .Payment .icon i {
  font-size: 15px;
  color: #3f4553;
  background: transparent;
}
.possection .Payment .icon p {
  color: #3f4553;
  padding: 8px 0;
  margin: 0;
  line-height: 0;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  font-weight: bold;
}
.possection .Payment .icon:hover,
.possection .Payment .icon.active,
.possection .Payment .icon:focus {
  background-color: darkgreen !important;
}
.possection .Payment .icon:hover i,
.possection .Payment .icon.active i,
.possection .Payment .icon:focus i,
.possection .Payment .icon:hover p,
.possection .Payment .icon.active p,
.possection .Payment .icon:focus p {
  color: white !important;
}
#cartbtn.disable {
  color: black !important;
  border: none !important;
  cursor: pointer !important;
  background-color: antiquewhite !important;
}
#cartbtn.disable:hover {
  background-color: antiquewhite !important;
  color: black !important;
  border: none !important;
  cursor: pointer !important;
}
.pos-btn-grp {
  margin: 0;
  display: grid;
  width: 100%;
  height: auto;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 10px;
  padding: 3px;
}
.pos-sum-btn {
  padding: 10px 5px 5px 3px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.pos-sum-btn .btn-fabgreen {
  flex: 1 1 45% !important;
}
.cartinv {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  align-items: center;
  font-size: 13px;
}
.cartinv sub {
  color: gray;
  font-weight: 700;
}
.discount {
  height: 35px;
  border: 0.5px solid #add8e6;
  border-radius: 5px;
  background: #f2f7fb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.discount button {
  color: rgba(0, 100, 0, 0.7);
  font-weight: 700;
  border: none;
  outline: none;
  text-transform: uppercase;
  background: transparent;
}
.discount button:hover {
  color: blueviolet;
}
.rypoint {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
/* ===================================== POS Right Menu ============================================ */
#pos-menu {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 5px;
  z-index: 999 !important;
  height: auto;
}
#pos-menu ul {
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  overflow: auto;
}
#pos-menu ul li {
  list-style: none;
  padding: 0 10px;
}
.companyname {
  text-align: left;
  color: darkgreen;
  font-size: 15px;
  font-weight: 700;
  word-wrap: break-word;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  column-gap: 12px;
  border-bottom: 1px solid #add8e6;
}
.companyname img {
  height: 30px;
  width: 30px;
  object-fit: cover;
}
#pos-menu ul li .mbtn {
  flex: 1 1 calc(100% / 4);
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  text-align: center;
  border: none;
  border-radius: 5px;
  border: 1px solid #add8e6;
  font-weight: 600;
  width: 100%;
  transition: 0.7s ease all;
  font-size: 15px;
  background-color: #f2f7fb;
}
#pos-menu ul li .mbtn i {
  font-size: 13px;
}
#pos-menu ul li .mbtn:hover {
  background: darkgreen !important;
  color: white !important;
  font-weight: 600;
}
#pos-menu ul li .mbtn:hover span {
  color: white !important;
  font-weight: 600;
}
#pos-menu ul li input {
  border: none;
  height: 40px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  padding: 0 10px;
  color: black;
  font-weight: 600;
}
#pos-menu ul li input:focus {
  border: 1px solid #add8e6;
}
.cash-out label {
  margin-top: 10px;
  text-transform: uppercase;
  font-weight: 700;
}
.pos-help {
  display: grid;
  grid-template-columns: auto;
  justify-content: flex-start;
}
.pos-help label {
  margin-top: 10px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: left;
}
#pos-menu ul::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
#pos-menu ul::-webkit-scrollbar-track {
  background: white;
  border-radius: 2px;
}
#pos-menu ul::-webkit-scrollbar-thumb {
  background-color: silver;
  border-radius: 2px;
}
/* ----------------------------------------Profile Management Style ----------------------------------------- */
.Org-Master {
  width: 100%;
  height: 100%;
  margin: auto;
}
.Org-Content {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 0 0 0 10px;
}
.pos-content {
  width: 100%;
  margin-left: 2px;
  position: relative;
}
.pos-content label {
  font-weight: 400;
}
.pos-dtlist {
  width: 25%;
  margin: 0 0;
  padding: 0;
  height: auto;
  border-radius: 0 0 5px 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: white;
}
.pos-dtlist .dt-content {
  width: 100%;
  height: 100vh;
  overflow: auto;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.pos-dtlist .dt-content button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 10px;
  outline: none;
  border: none;
  border-bottom: 1.2px solid #add8e6;
  transition: all 0.3s ease;
  background: transparent;
  color: black;
}
.pos-dtlist .dt-content button label {
  color: black;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 13px;
  width: 100%;
  padding: 0;
  margin: 0;
}
.pos-dtlist .dt-content button img {
  height: 30px;
  width: 30px;
  border-radius: 5px;
  background: white;
  border: 1px solid #add8e6;
  margin-right: 5px;
}
.pos-dtlist .dt-content button i {
  color: darkgreen;
  opacity: 0.8;
  font-size: 15px;
  transition: all 0.3s ease;
}
.pos-dtlist .dt-content button:hover,
.pos-dtlist .dt-content button:focus {
  border-radius: 5px;
}
.pos-dtlist .dt-content button:hover label,
.pos-dtlist .dt-content button:focus label {
  color: blue;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}
.pos-dtlist .dt-content button i:hover {
  color: blue;
  font-weight: 600;
}
.pos-dtlist .dt-content::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.pos-dtlist .dt-content::-webkit-scrollbar-track {
  background: white;
  border-radius: 2px;
}
.pos-dtlist .dt-content::-webkit-scrollbar-thumb {
  background-color: silver;
  border-radius: 2px;
}
.poshd-btn-grp {
  display: flex;
  justify-content: center;
  column-gap: 10px;
}
.poshd-btn-grp .btn {
  background: rgb(34, 179, 120);
  color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: 400;
  font-size: 12px;
  border: none;
  padding: 0 10px;
  color: white;
  height: 30px;
  column-gap: 5px;
}
.poshd-btn-grp .btn.option {
  border-radius: 5px;
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  width: 35px;
  border: 1px solid #add8e6;
  display: flex;
  column-gap: 5px;
  opacity: 0.9;
}
/* ------------------------------------ Branch Cart -------------------------------------------- */
.pos_cart {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding: 0;
  background: white;
}
.pos_cart .cart-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 10px;
  color: darkgreen;
}
.pos_cart .cart-content {
  width: 100%;
  font-size: 13px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-transform: uppercase;
  padding: 0 20px;
  column-gap: 30px;
}
.pos_cart .cart-content .ct-lbl {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  color: black;
}
.pos_cart .cart-content .ct-lbl .ct-txt {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
  font-size: 13px;
  font-weight: 500;
}
.pos_cart .cart-content .ct-lbl .ct-txt.branch i {
  color: darkgreen;
}
.pos_cart .cart-content .ct-lbl .ct-txt.branch {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  text-align: left;
  column-gap: 10px;
}
.pos_cart .cart-tab {
  margin: 0;
  padding: 0;
  width: 100%;
  border-radius: 3px;
  height: 30px;
  position: relative;
  z-index: 999;
  background: white;
}
.pos_cart .cart-tab label {
  display: inline-block;
  font-size: 14px;
  height: 36px;
  line-height: 36px;
  width: 25%;
  text-align: center;
  color: black;
  position: relative;
  transition: 0.25s background ease;
  cursor: pointer;
  margin-left: 5px;
}
.pos_cart .cart-tab img {
  height: 25px;
  width: 30px;
}
.pos_cart .cart-tab i {
  height: 30px;
  width: 30px;
}
.pos_cart .cart-tab #subtabline {
  position: absolute;
  height: 3px;
  background: #1e88e5;
  width: 25%;
  bottom: 0;
  left: 0;
  transition: 0.35s ease;
  border-radius: 2px 2px 0 0;
}
/* ---------------------------------------POS Product table ------------------------------------- */
.input-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
  padding: 0 5px;
  margin: 10px 10px 0 0 !important;
}
.input-search input:nth-child(2),
.input-search input:nth-child(3) {
  flex: 1 1 30%;
  width: 50%;
}
.input-search input:first-child {
  width: 100%;
}
.pos-view {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.pos-view button {
  width: 100%;
  background: none;
  border: none;
  outline: none;
  display: flex;
  justify-content: flex-start;
  text-align: left;
  align-items: center;
  padding: 0 5px;
  transition: all 0.3s ease;
  height: auto;
  font-size: 13px;
  text-transform: capitalize;
  border-bottom: 1px solid #add8e6;
  cursor: pointer;
}
.pos-view button .bill-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 5px;
  width: 100%;
}
.pos-view button .bill-content h6 {
  width: 100%;
  font-size: 14px;
  font: 500 20px/35px "Roboto", sans-serif;
}
.trans-cont {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 10px;
}
.trans-cont .transview1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}
.trans-cont .transview1 label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: blueviolet;
}

.popup-body .pos-view button:hover {
  border: none;
}
.popup-body .pos-view button:hover .bill-content,
.popup-body .pos-view button.active .bill-content {
  border-radius: 5px;
  color: white !important;
  background-color: darkgreen;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.popup-body .pos-view button:hover .bill-content h6,
.popup-body .pos-view button.active .bill-content h6,
.popup-body .pos-view button:hover .bill-content label,
.popup-body .pos-view button.active .bill-content label {
  color: white !important;
}
.Pospop-content label {
  color: black;
  font-weight: 600;
  font-size: 12px;
}
.popup-body .pos-view button:hover .popup-body .pos-view button:focus {
  border-radius: 5px;
  color: blue;
}
.view-close {
  position: absolute;
  right: 10px;
  top: 5px;
  width: 25px;
  height: 25px;
  color: darkgreen;
  border: 1px solid lightgreen;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  transition: 0.8s ease all;
  cursor: pointer;
  z-index: 1001;
}
.view-close:hover {
  background: darkgreen;
  color: white;
  font-weight: 700px;
  border: none;
}
/* ------------------------ POS Receipt ------------------------------------------- */
.rctbody input {
  border: none;
  height: 40px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 0 10px;
  color: black;
  font-weight: 600;
}
.rctbody input:focus {
  border: 1px solid #add8e6;
}
.rpt-billamt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 5px;
  width: 80%;
  margin: auto;
  height: 50px;
}
.rct-label {
  font-weight: 600;
  font-size: 15px;
  color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.rpt-billamt label {
  color: gray;
  font-weight: 700;
  font-size: 20px;
}
.paidamt {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: auto;
}
.paidamt input {
  border: none;
  border-radius: 3px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  font-size: 20px;
  color: black;
  font-weight: 700;
  opacity: 0.8;
  height: 50px;
}
.paidamt input:focus {
  border: 1px solid #add8e6;
  color: black;
  font-weight: 700;
  opacity: 0.8;
}
.ss-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  row-gap: 10px;
}
.sscont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}
.sscont label:nth-of-type(odd) {
  text-align: left;
  font-weight: 600;
}
.sscont label:nth-of-type(even) {
  text-align: right;
  font-weight: 700;
  color: black;
}
.sscont:last-child {
  border-bottom: 1px dashed #add8e6;
}
.ssinput {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px dashed #add8e6;
}
.ssinput label {
  font-weight: 700;
}
.ssinput input {
  width: 150px;
  text-align: right;
  border: 1px solid #add8e6;
  font-weight: 700;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
/* ----------------------------------- Tax Search Input style ----------------------------------- */
.tax-input {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
}
.tax-input input {
  height: 35px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #add8e6;
  outline: none;
  padding: 0 10px;
}
.tax-input i {
  position: absolute;
  border-radius: 0 5px 5px 0;
  right: 0;
  width: 30px;
  height: 100%;
  background: darkgreen;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  font: 600;
  font-size: 14px;
  cursor: pointer;
}
/* ----------------------------- POS Button  ---------------------------------------- */
.transtable {
  margin-top: 10px;
}
.td-check {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
}
/* ----------------- Session Master ---------------------- */
.session-summary {
  margin: 0;
  margin-top: 10px;
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  padding: 0;
  background: #fff;
}
.session-summary li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  margin: 0;
  line-height: 15px;
}
.session-summary li p {
  font-size: 13px;
  color: black;
}
.session-summary li p:last-child {
  font-weight: bold;
}
/* ----------------- POS Tooltip-------------------------- */
.pos-btn-group {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  column-gap: 10px;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 2px 0;
}
.pos-tooltip {
  position: relative;
  padding: 0;
}
.pos-tooltiptxt {
  position: absolute;
  top: -10px;
  left: -10px;
  padding: 2px 5px;
  background-color: black;
  color: var(--white-color);
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 10px;
  border-radius: 5px 3px 5px 3px;
  z-index: 100;
  display: none;
}
.pos-tooltiptxt::after {
  content: " ";
  position: absolute;
  bottom: -10px; /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
.pos-tooltip:hover .pos-tooltiptxt {
  display: block;
}
.pos-btn-group .pos-tooltiptxt {
  z-index: 999;
}
@media (max-width: 900px) {
  .pos-body {
    grid-template-columns: 1fr;
  }
  .pos-itemlist {
    width: 97%;
    margin: auto;
  }
  .pos-wrapper {
    grid-template-columns: auto;
    padding: 5px;
  }
}
.touch-btn {
  margin-top: 20px;
}
.switch-touch {
  position: relative;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.toggle {
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0;
  border-radius: 5px;
  background-color: darkgreen;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.pc,
.mobile {
  width: 50%;
  height: 30px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.switch-touch .toggle.active {
  right: 0;
  transform: translateX(100%);
}
.pro_mobile_view {
  position: relative;
  width: 100%;
  height: 56vh;
  max-height: 56vh;
  margin-top: 5px;
  padding: 0 2px;
  overflow: auto;
}
.mobile_view {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  grid-gap: 10px;
  padding: 5px;
}
.mobile_view button {
  outline: none;
  border: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
}
.pro-img {
  display: block;
  padding: 5px;
  background: #fff;
  border-radius: 10px;
}
.pro-img img {
  object-fit: cover;
  height: 100px;
  width: 100%;
  border-radius: 10px;
}
.pro-img label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
