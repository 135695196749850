* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  width: 100%;
  height: 100vh;
  background: var(--white-color);
  transition: background-color all 0.3s ease-in-out;
  font-family: var(--Roboto) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
}
:root {
  --title-color: #464270;
  --white-color: #f7f6fb;
  --background-color: #ecf6fd;
  --primary: #1b5e20;
  --grey: #f1f0f6;
  --dark-grey: #8d8d8d;
  --light: #fff;
  --dark: #000;
  --green: #81d43a;
  --light-green: #e3ffcb;
  --blue: #1775f1;
  --light-blue: #d0e4ff;
  --dark-blue: #0c5fcd;
  --red: #fc3b56;
  --bordercolor: lightblue;
  --titlefontweight: 700;
  --green: darkgreen;
  --textWhite: white;
  --textfontsize: 15px;
  --headerfontsize: 20px;
  --containerboxshadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --containerborderradius: 10px;
  --img-radius: 10px;
  --Icon-color: #678efe;
  --btn-border: #cdd9ed;
  --secondary: #b9f6ca;
  --black: #000;
  --white: #fff;
  --header-height: 3rem;
  --nav-width: 68px;
  --first-color: #1b5e20;
  --first-color-light: #afa5d9;
  --normal-font-size: 1rem;
  --z-fixed: 100;
  --select-arrow: var(--select-border);
  --Backgound-color: #ecf6fd !important;
  --btn-color-green: #3a833a;
  --input-color: #99a3ba;
  --input-border: #cdd9ed;
  --border-color: #add8e6;
  --input-background: #fff;
  --input-placeholder: #cbd1dc;
  --input-border-focus: #275efe;
  --group-color: var(--input-color);
  --group-border: var(--input-border);
  --group-background: #eef4ff;
  --group-color-focus: #fff;
  --group-border-focus: var(--input-border-focus);
  --lbl-color: #3f4553;
  --Input-Font: "Mukta Malar", Arial;
  --Lato: "Lato", sans-serif;
  --Poppins: "Poppins", sans-serif;
  --Roboto: "Roboto", sans-serif;
  --Roboto1: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
.main-container {
  display: grid;
  grid-template-areas:
    "top-menu"
    "content";
  grid-template-rows: 50px 1fr;
  grid-template-columns: 1fr;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  overflow: hidden;
  transition: all 0.3s ease-in;
  gap: 0;
}
.main-container.show {
  display: grid;
  grid-template-areas:
    "side-menu top-menu"
    "side-menu content";
  grid-template-rows: 50px 1fr;
  grid-template-columns: 250px 1fr;
  transition: all 0.3s ease-in;
}
.side-menu {
  background-color: var(--primary);
  transition: left 0.5s ease;
  height: 100%;
  overflow: auto;
  transition: all 0.3s ease-in;
  display: none;
}
.main-container.show .side-menu {
  grid-area: side-menu;
  display: block;
  height: 100%;
  overflow: auto;
}
.top-menu {
  grid-area: top-menu;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 60% 40%;
  grid-gap: 10px;
  align-items: center;
  transition: all 0.5s ease-in-out;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-bottom: 1px solid #ddd;
  padding: 10px;
  transition: all 0.3s ease-in;
}
.content {
  grid-area: content;
  display: grid;
  grid-template-areas:
    "left-list main-content"
    "left-list button-list";
  grid-template-columns: 25% 1fr;
  grid-template-rows: 1fr 50px;
  gap: 0;
  padding: 0;
  margin: 0;
  transition: all 0.3s ease-in;
}
.content1 {
  grid-area: content;
  display: grid;
  grid-template-areas:
    "main-content1"
    "button-list";
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 50px;
  gap: 0;
  padding: 0;
  margin: 0;
  transition: all 0.3s ease-in;
}
.left-list {
  grid-area: left-list;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  background-color: #fff;
  border-right: 1px solid var(--btn-border);
}
.main-content {
  grid-area: main-content;
  background-color: #fff;
  padding: 0 20px 50px 10px;
  overflow-y: auto;
  max-height: calc(100vh - 100px);
}
.main-content1 {
  grid-area: main-content1;
  background-color: #fff;
  padding: 0 0 50px 0;
  overflow-y: auto;
  max-height: calc(100vh - 100px);
}
.button-list {
  grid-area: button-list;
  height: auto;
  min-height: 50px;
  border-top: 1px solid var(--btn-border);
  background-color: #f9f9fb;
  margin: 0;
  width: 100%;
  padding: 10px 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.top-menu .ScreenName {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-bars {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: left 0.4s ease-in-out;
}
.top-menu .ScreenName h5 {
  height: 100%;
  display: flex;
  align-items: center;
  color: darkgreen;
  font-weight: bold;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}
.Header-Menu {
  display: flex;
  justify-content: flex-end !important;
  align-items: center;
  text-align: center;
  column-gap: 10px;
  cursor: pointer;
  padding-right: 10px;
  height: 100%;
}
.Header-Menu a {
  color: darkgreen;
}
.lbltooltip a span {
  border-radius: 10px;
  padding: 3px;
  border: 1px solid #ddd;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.Profile-Img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #ddd;
  transition: all 0.4s ease-in-out;
}
.Profile-Img img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.Profile-Img:hover {
  transform: scale(1.1);
}
.right_menu {
  display: none;
  position: absolute;
  top: 40px;
  right: 20px;
  min-width: 250px;
  height: auto;
  padding: 0;
  background-color: #fff;
  border-radius: 10px;
  transition: all 0.4s ease;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 1;
}
.right_menu li {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  line-height: 50px;
  padding: 0 10px;
  transition: all 0.5s ease;
}
.profile-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profile-desc .img-wrapper {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  margin-top: 10px;
}
.profile-desc .img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.profile-desc .org-cont {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.profile-desc .org-cont h5 {
  font-size: 12px;
  line-height: 0;
  color: black;
}
.profile-desc .org-cont h5 span {
  color: gray;
}
.profile-desc .org-cont h5:hover {
  text-decoration: underline;
}
.profile-desc .org-cont .baclog {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  column-gap: 20px;
}
.profile-desc .org-cont .baclog label {
  font-size: 12px;
  color: red;
  line-height: 0;
  cursor: pointer;
}
.profile-desc .org-cont .baclog label:hover {
  color: blueviolet;
}
.profile-desc .org-cont .baclog label i {
  color: gray;
  margin-right: 5px;
}
.right_menu.active {
  display: grid;
  grid-template-columns: auto;
}
.side-menu::-webkit-scrollbar {
  display: none;
}
.btn-bars span {
  border-radius: 3px;
  transition: all 0.4s ease-in-out;
  border: 1px solid var(--primary);
  padding: 5px 10px;
  font-size: 13px;
  cursor: pointer;
}
.btn-bars span:hover {
  background-color: var(--primary);
  color: var(--white);
}
.btn-bars.click {
  left: 225px;
}
.btn-bars.click span {
  background-color: var(--primary);
  color: var(--white);
}
.btn-bars.click span:before {
  content: "\f00d";
}
.cart-rotate {
  -moz-transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  transition: all 0.3s linear;
  color: #333;
}
.cart-rotate.cart-down {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.side-menu .logo {
  font-weight: bold;
  min-width: 200px;
  min-height: 50px;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  padding-left: 10px;
  text-align: left;
  background-color: var(--white-color);
  border-bottom: 1px solid var(--primary);
  border-right: 1px solid var(--primary);
  cursor: pointer;
  text-decoration: none;
  color: black;
  font-size: 10px;
}
.logo-wrapper {
  width: 35px;
  height: 35px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 50%;
  border: 1px dashed #ddd;
}
.logo-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.side-menu .logo i {
  padding: 5px;
  display: flex;
  align-items: center;
  font-size: 15px;
  border: 1px solid #ddd;
  border-radius: 50%;
}
.side-menu .logo i:last-child {
  font-size: 15px;
  padding-top: 5px;
  border: none;
}
.side-menu .logo:hover {
  font-weight: bold;
  color: blue;
}
.side-menu ul {
  background-color: var(--primary);
  height: 100%;
  width: 100%;
  list-style: none;
  padding-left: 0;
}
.nav-item {
  list-style: none;
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  text-align: left;
  border-bottom: 1px solid #b9f6ca26;
}
.nav-item a {
  position: relative;
  width: 100%;
  text-decoration: none;
  font-size: 15px;
  height: 50px;
  color: var(--first-color-light);
  padding: 0 10px;
  display: grid;
  grid-template-columns: 30px auto 30px;
  align-items: center;
  text-align: left;
  cursor: pointer;
  transition: all 0.3s ease;
}
.nav-item a .fa-caret-down {
  float: right;
  -moz-transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  transition: all 0.3s linear;
}
.nav-item a:hover,
.nav-item.active a {
  color: var(--white);
  border-left: 3px solid var(--white);
  border-radius: 5px 0 0 5px;
  padding-left: 15px;
}
.nav-item a img {
  width: 22px;
  height: 22px;
  filter: invert(78%) sepia(13%) saturate(3207%) hue-rotate(300deg)
    brightness(1000%) contrast(80%);
}
.nav-item ul {
  display: none;
}
.nav-item.active ul {
  position: relative;
  background-color: #43a04754;
}
.nav-item.active ul li {
  list-style: none;
  text-decoration: none;
  border-bottom: none;
  white-space: nowrap;
}
.nav-item.active ul.show {
  display: block;
}
.nav-item.active ul li a {
  font-size: 12px;
  color: var(--first-color-light) !important;
  padding-left: 30px;
  color: var(--white);
  height: 40px;
  border-left-color: transparent;
  font-weight: 400;
  transition: all 0.3s ease;
}
.nav-item.active ul li a:hover {
  color: var(--white) !important;
  border-left-color: transparent !important;
  padding-left: 40px;
}
.nav-item a:hover .master-grid span {
  flex: 1 1 30%;
  border: 2px solid var(--white);
  border-radius: 2px;
}
.master-grid {
  width: 18px;
  height: 18px;
  padding: 2px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 2px;
  row-gap: 2px;
}
.master-grid span {
  flex: 1 1 30%;
  border: 2px solid var(--first-color-light);
  border-radius: 2px;
}
/* Responsive Design */
@media (max-width: 768px) {
  .main-container {
    grid-template-areas:
      "top-menu"
      "content";
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
    overflow: auto;
  }
  .main-container.show {
    grid-template-areas:
      "side-menu top-menu"
      "side-menu content";
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr 1fr;
  }
  .main-container.show .side-menu {
    width: 250px;
  }
  .top-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .top-menu .ScreenName {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .top-menu .ScreenName .btn-bars {
    position: absolute;
    top: 20px;
    left: 10px;
  }
  .content {
    display: grid;
    grid-template-areas:
      "left-list"
      "main-content"
      "button-list";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr auto;
  }
  .content .main-content {
    height: 100%;
  }
  .button-list {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
  }
}
