/* ----------------------------------------   Landing Page Style--------------------------- */
.landing-page {
  display: grid;
  grid-template-areas:
    "Nav-Menu"
    "page-content";
  grid-template-rows: 50px 1fr;
  grid-template-columns: 1fr;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  overflow: hidden;
  transition: all 0.3s ease-in;
  gap: 0;
}
.Nav-Menu {
  grid-area: Nav-Menu;
  margin: 0;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: 3;
  padding: 10px 0;
}
.Nav-Menu img {
  left: 10px;
  position: absolute;
  height: 150px;
  width: 150px;
}
#togglebar {
  position: absolute;
  top: 10px;
  right: 20px;
  padding: 1px 7px;
  font-size: 17px;
  font-weight: bold;
  border-radius: 3px;
  color: var(--white-color);
  background: var(--primary);
  cursor: pointer;
  z-index: 1;
  transition: all 0.4s ease;
  display: none;
}
#togglebar.click {
  background: transparent;
  color: var(--primary);
}
#togglebar.click span:before {
  content: "\f00d";
}
#togglebar:hover {
  color: var(--white-color);
  background: var(--primary);
  font-size: 17px;
}
.Navmenu-item {
  height: 100%;
  margin: 0;
  padding: 0;
  padding-right: 20px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  cursor: pointer;
  background: #fff;
}
.Navmenu-item li {
  list-style: none;
  text-transform: capitalize;
  transition: all 0.5s ease;
}
.Navmenu-item li a {
  font-weight: bold;
  font-size: 14px;
  color: #31934a;
  transition: all 0.5s ease;
}
.Navmenu-item li hr {
  width: 30%;
  margin: auto;
  border: none;
  height: 4px;
  background: #9eb946;
  border-radius: 3px;
  display: none;
  opacity: 1;
  transition: all 0.5s ease;
}
.Navmenu-item li:hover hr,
.Navmenu-item li.active hr {
  display: block;
  transition: all 0.5s ease;
}
.Navmenu-item li:hover a,
.Navmenu-item li.active a {
  color: var(--primary);
  transition: all 0.5s ease-in-out;
  transform: scale(1.1);
}
.Navmenu-item .btn-login {
  border: none;
  outline: none;
  width: 100px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary);
  color: var(--white-color);
  border-radius: 5px;
  font-weight: bold !important;
  transition: all 0.4s ease;
  font-size: 12px;
}
.Navmenu-item .btn-login:hover {
  font-size: 13px;
}
.page-content {
  grid-area: page-content;
  display: grid;
  grid-template-areas:
    "page-sec1"
    "page-sec2"
    "page-sec3"
    "page-sec4"
    "page-sec5"
    "page-sec6"
    "page-sec7"
    "page-sec8";
  grid-template-rows: auto 50px auto 50px auto 50px auto auto;
  grid-template-columns: 1fr;
  height: 100%;
  width: 100vw;
  background-color: #fff;
  overflow: auto;
  transition: all 0.3s ease-in;
  gap: 0;
}
.page-sec1 {
  grid-area: page-sec1;
  position: relative;
  width: 100%;
  min-height: 60vh;
  background-color: #ecf6fd;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.page-sec1 .cont {
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  row-gap: 10px;
  padding: 10px 0;
  text-transform: uppercase;
}
.page-sec1 .cont h1 {
  font-weight: 700;
  font-size: 45px;
  color: darkgreen;
}
.page-sec1 .cont h2 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 20px;
  color: black;
  margin-bottom: 20px;
}
.page-sec1 .cont p {
  margin: 0;
  padding: 0;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  line-height: 30px;
}
.page-sec1 .title-img {
  position: relative;
  display: grid;
  grid-template-areas:
    "exp-img"
    "billimg";
  grid-template-rows: 100px 1fr;
  grid-template-columns: 1fr;
  height: 100%;
  width: 100%;
  gap: 0;
}
.exp-img {
  grid-area: exp-img;
}
.billimg {
  grid-area: billimg;
}
.exp-img img,
.billimg img {
  object-fit: cover;
  width: 100px;
  height: 100px;
}
.page-sec2 {
  grid-area: page-sec2;
  width: 100%;
  font-weight: bold;
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
}
.page-sec3 {
  grid-area: page-sec3;
  width: 100%;
  position: relative;
  white-space: nowrap;
  display: flex;
  cursor: pointer;
  background: white;
}
.page-sec3 .mdl-card {
  height: auto;
  width: auto;
  min-width: 300px;
  border-radius: 25px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid #ddd;
  margin: 0 10px;
}
.mdl-card h1 {
  width: 100%;
  padding: 10px 0;
  background-color: darkgreen;
  color: white;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  font-size: 15px;
  border-radius: 25px 25px 0 0;
}
.mdl-card .mdl-cont {
  padding: 0 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  white-space: nowrap;
}
.mdl-card .mdl-cont h6 {
  margin: 10px 0;
  font-size: 12px;
  color: #000000;
  font-weight: 600;
}
.page-sec4 {
  grid-area: page-sec4;
  width: 100%;
  font-weight: bold;
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
}
.page-sec5 {
  grid-area: page-sec5;
  position: relative;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 0.7rem;
}
.ft-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  padding: 10px;
  border-radius: 25px;
  cursor: pointer;
}
.la-img-wrapper {
  position: relative;
  width: 100%;
  height: 200px;
  border-radius: 25px;
  padding: 0;
}
.la-img-wrapper img {
  margin: 0;
  height: 100%;
  width: 100%;
  object-fit: fill;
  border-radius: 25px;
}
.ft-box h5 {
  width: 100%;
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  color: black;
  text-transform: uppercase;
  margin-top: 15px;
}
.ft-box p {
  margin-top: 0;
  width: 100%;
  font-size: 14px;
  text-align: left;
  white-space: wrap;
}
.ft-box:hover {
  background-color: #faf5f5;
}
.page-sec6 {
  grid-area: page-sec6;
  width: 100%;
  font-weight: bold;
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
}
.page-sec7 {
  grid-area: page-sec7;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}
.sup-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
  border-right: 1px solid rgb(218, 236, 241);
}
.sup-img:last-child {
  border: none;
}
.sup-img img {
  height: 150px;
  width: 150px;
}
.sup-img label {
  white-space: nowrap;
  color: gray;
  font-weight: 700;
  text-transform: uppercase;
}
.sup-img:hover label {
  color: #289dd8;
  text-decoration: underline;
}
.page-sec8 {
  grid-area: page-sec8;
  background: #f1f1f1;
  color: black;
  border-radius: 10px;
  padding: 0;
  margin: 0;
}
.footer-cont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
  flex-wrap: wrap;
  padding: 20px;
  row-gap: 20px;
}
.footer-cont li {
  flex: 1 1 calc(100% / 3);
  list-style: none;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  font-weight: 700;
}
.footer-cont li ul {
  padding: 0;
  margin: 0;
  line-height: 40px;
}
.footer-cont li ul li {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0;
  margin: 0;
}
.footer-cont li h1 {
  font-size: 25px;
  text-transform: uppercase;
}
.footer-cont li p {
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
  color: black;
  opacity: 0.8;
}
.footer-cont li p i {
  color: gray;
}
.footer-cont li ul li a {
  color: black;
  text-align: left;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 15px;
}
.Social-Icon {
  margin-left: -10px;
  column-gap: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.fa-facebook {
  color: #0165e1;
}
.fa-youtube {
  color: #c4302b;
}
.fa-linkedin {
  color: #0a66c2;
}
.fa-chrome {
  color: #4c8bf5;
}
.fa-instagram {
  font-size: 20px;
  color: transparent;
  background: -webkit-radial-gradient(
    30% 107%,
    circle,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: -o-radial-gradient(
    30% 107%,
    circle,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: -webkit-radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background-clip: text;
  -webkit-background-clip: text;
}
.Copy-rights {
  background: #f1f1f1;
  color: black;
  font-weight: 400;
  height: 50px;
  padding-top: 10px;
  display: flex;
  opacity: 0.8;
  justify-content: center;
  width: 100%;
  text-align: center;
  font-size: 14px;
  text-transform: lowercase;
}

/* ---------------------------------   Login Page Style--------------------------- */

.login-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: first baseline;
}
.login-content {
  position: relative;
  color: black;
  background: #fff;
  padding: 0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 15px;
  margin-top: 3%;
}
.login-Img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
}
.login-Img img {
  width: 250px;
  height: 60px;
  object-fit: cover;
}
.login-header {
  display: block;
  line-height: 10px;
}
.login-header h6 {
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 500;
}
.login-header label {
  color: green;
  font-size: 15px;
  text-transform: capitalize;
}
.login-input {
  position: relative;
  padding: 0 10px;
}
.login-input label {
  font-weight: 400;
}
.login-control {
  margin: 0;
  padding: 0;
  padding-top: 10px;
}
.login-usepass {
  display: flex;
  align-items: center;
  text-align: left;
  column-gap: 10px;
  padding: 2px 10px;
  border-radius: 8px;
  font-size: 12px;
  background: #f5f3f3;
  border-right: 3px solid lightgray;
  border-left: 3px solid lightgray;
  transition: all 0.5s ease-in-out;
}
.login-usepass input {
  min-width: 250px;
  height: 35px;
  padding: 0 10px;
  border: none;
  outline: none;
  background: #f5f3f3;
  color: #000000;
  font-weight: 600;
  font-size: 13px;
  border-left: 2px solid lightgray;
}
.login-usepass:hover {
  transform: scale(1.03);
}
.login-usepass input::placeholder {
  color: black;
  font-weight: 500;
}
.login-usepass i {
  font-size: 12px;
  color: green;
}
.login-btn {
  width: 100%;
  padding: 10px 10px 15px 10px;
}
.login-btn button {
  width: 80%;
  height: 40px;
  margin: auto;
  border: none;
  outline: none;
  background: green;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
}
.login-btn button:hover {
  background: var(--primary);
  color: var(--white-color);
  border: 1px solid yellowgreen;
}
.login_progress {
  position: absolute;
  left: 5px;
  bottom: 0;
  height: 7px;
  width: 97%;
  transform: scaleX(0);
  transform-origin: left;
  background-image: linear-gradient(to right, #539bdb, #3250bf);
  border-radius: 0 0 15px 15px;
  animation: load 0.5s 0.001s linear forwards;
}
@keyframes fade-in {
  5% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes load {
  to {
    transform: scaleX(1);
  }
}
@keyframes blinking {
  0% {
    color: red;
  }
  47% {
    color: blue;
  }
  62% {
    color: red;
  }
  97% {
    color: blue;
  }
  100% {
    color: red;
  }
}
